import type { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import { checkUserAuthOnServer, usePasswordlessLogin } from '@mwl/core-lib';

import { PopupRecovery } from '@/components/PopupRecovery/PopupRecovery';
import { defaultLocale, routes } from '@/constants';
import type { CasinoLayoutProps } from '@/Layouts/CasinoLayout/CasinoLayout.types';
import type { MainPageProps } from '@/modules/main/MainPage.types';
import { getMainPageData } from '@/modules/main/MainPage.utils';
import { wrapperStore } from '@/store/store';

const CasinoLayout = dynamic(() => import('@/Layouts/CasinoLayout/CasinoLayout').then((mod) => mod.CasinoLayout));
const IndexPage = dynamic(() => import('@/modules/main/MainPage').then((mod) => mod.MainPage));

export interface CasinoLayoutServerProps
  extends Pick<CasinoLayoutProps, 'banners'>,
    Pick<MainPageProps, 'liveLines' | 'pregameLines' | 'topProviders' | 'topGames'> {}

const Home: NextPage<CasinoLayoutServerProps> = ({ banners, liveLines, pregameLines, topProviders, topGames }) => {
  usePasswordlessLogin({
    profileUrl: routes.profile.home,
  });

  return (
    <>
      <PopupRecovery />
      <CasinoLayout navigation={false} banners={banners}>
        <IndexPage liveLines={liveLines} pregameLines={pregameLines} topProviders={topProviders} topGames={topGames} />
      </CasinoLayout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<CasinoLayoutServerProps> = wrapperStore.getServerSideProps(
  ({ dispatch }) =>
    async ({ locale = defaultLocale, req, res }) => {
      console.warn(req.headers);
      const { isAuth, localServerAxiosInstance } = await checkUserAuthOnServer({ dispatch, locale, res, req });

      const { translations, banners, topLines, topProviders, topGames } = await getMainPageData({
        locale,
        isAuth,
        axiosInstance: localServerAxiosInstance,
        req,
      });

      return {
        props: {
          ...translations,
          ...banners,
          ...topLines,
          topProviders,
          topGames,
        },
      };
    },
);

export default Home;
